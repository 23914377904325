import React from "react"

import { APP_URI } from '../configs';
import {Link} from 'gatsby'

import phone from '../images/phone.svg'

import '../scss/error.scss'

export default class Error extends React.Component {

  render(){
    return(
    <div className="errorPage">

      <h1>      <img src={phone} />Para usar Troop en tu telefono, descarga la APP<br/><a className="button--global" onClick={() => window.history.back()}>Volver</a>
      <Link to={'/'} className="button--global">Ir a la home</Link></h1>
    </div>
    );
  }
}
